import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from './App';
import ChessPage from './chess/ChessPage';
import HangboardPage from './hangboard/HangboardPage';
import RunalyticsPage from './runalytics/Runalytics';
import PatternsPage from './patterns/PatternsPage';
import PhotographyPage from './photography/PhotographyPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/chess" element={<ChessPage />} />
        <Route path="/hangboard" element={<HangboardPage />} />
        <Route path="/exercise" element={<RunalyticsPage />} />
        <Route path="/patterns" element={<PatternsPage />} />
        <Route path="/photography" element={<PhotographyPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
