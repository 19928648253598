import './Chess.css';

import { useState, useEffect } from 'react';

import ChessBoard from 'chessboardjsx';
import { ChessInstance, ShortMove } from 'chess.js';

const Chess = require('chess.js');

function ChessPage() {
    const [game, setGame] = useState<ChessInstance>(new Chess())
    const [uiFen, setUIFen] = useState<string>(game.fen())
    const [prevFen, setPrevFen] = useState<string>("")

    /**
     * Always use this function to change game state.
     * This ensures that the game state and UI are in sync.
     */
    function applyMove(move: ShortMove | string) {
        const valid = game.move(move, { sloppy: true })
        if (valid) {
            setPrevFen(uiFen)
            setUIFen(game.fen())
        }
    }

    async function getMoveFromAPI() {
        fetch(
            'https://kavfe0anw4.execute-api.us-west-2.amazonaws.com/dev/move?position=' + game.fen(),
            {
                method: 'GET',
                headers: {
                    "Accept": "application/json"
                },
            }
        ).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    const move = json['recommended_move']
                    console.log("Received move: " + move)
                    applyMove(move)
                });
            }
        })
    }

    /**
     * When the position changes, make a move as black if it is black's turn.
     */
    useEffect(() => {
        if (game.turn() == 'b' && !game.game_over()) {
            getMoveFromAPI()
        }
    }, [uiFen])

    /**
     * This is the handler for the human move.
     */
    const onDrop = (drop: any) => {
        const move = {
            from: drop.sourceSquare,
            to: drop.targetSquare,
        }
        applyMove(move)
    }

    const onRestart = () => {
        game.reset()
        setUIFen(game.fen())
    }

    return (
        <div className='chess'>
            <h1>Chess</h1>
            <div className='row'>
                <div className='narrow board'>
                    <ChessBoard
                        draggable={true}
                        position={uiFen}
                        onDrop={onDrop}
                        calcWidth={() => 400}
                    />
                </div>
                <div className='wide'>
                    <h2>
                        RAI MK.I
                    </h2>
                    <p>
                        Minimax with an evaluation function that only considers point differences in material.
                    </p>
                    <button onClick={onRestart}>Restart</button>
                    <div>Current FEN string: </div>
                    <div className='fen'>{uiFen}</div>
                    <div>Previous FEN string: </div>
                    <div className='fen'>{prevFen}</div>
                </div>
            </div>
        </div>
    )
}

export default ChessPage;
