import "./Photography.css";

import { useState } from "react";

interface LoadedPhotoProps {
  url: string;
  caption: string;
}

function LoadedPhoto(props: LoadedPhotoProps) {
  const [photoLoaded, setPhotoLoaded] = useState(false);

  return (
    <>
      <div style={photoLoaded ? {} : { display: "none" }}>
        <img
          src={props.url}
          onLoad={() => {
            setPhotoLoaded(true);
          }}
        />
        <p className="caption">{props.caption}</p>
      </div>
      <div className="loading" style={!photoLoaded ? {} : { display: "none" }}>
        [loading...]
      </div>
    </>
  );
}

export default LoadedPhoto;
