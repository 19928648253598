import './App.css';
import { Link } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';
import { useState } from 'react';

declare type TypingSpeed = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59 | 60 | 61 | 62 | 63 | 64 | 65 | 66 | 67 | 68 | 69 | 70 | 71 | 72 | 73 | 74 | 75 | 76 | 77 | 78 | 79 | 80 | 81 | 82 | 83 | 84 | 85 | 86 | 87 | 88 | 89 | 90 | 91 | 92 | 93 | 94 | 95 | 96 | 97 | 98 | 99;

function App() {
  const [linksVisible, setLinksVisible] = useState<boolean>(false)


  const randomSpeed = (): TypingSpeed => {
    return Math.floor(Math.random() * 99) + 1 as TypingSpeed;
  }

  return (
    <div className="App">
      <div className="Header">
        <TypeAnimation sequence={
          [
            'rileychang.com',
            () => {
              setLinksVisible(true)
            }
          ]} cursor={true} speed={75}
        />
      </div>
      <div className="About">
        This is my website! My random (often unfinished) side projects live here. You can follow my sewing projects on Instagram <a href='https://www.instagram.com/rat.gear'>@rat.gear</a>.
      </div>
      {
        linksVisible ?
          <div className="Links" >
            <Link to="/chess"><TypeAnimation sequence={['/chess']} cursor={false} speed={randomSpeed()} /></Link>
            <Link to="/exercise"><TypeAnimation sequence={['/exercise']} cursor={false} speed={randomSpeed()} /></Link>
            <Link to="/hangboard"><TypeAnimation sequence={['/hangboard']} cursor={false} speed={randomSpeed()} /></Link>
            <Link to="/patterns"><TypeAnimation sequence={['/patterns']} cursor={false} speed={randomSpeed()} /></Link>
            <Link to="/photography"><TypeAnimation sequence={['/photography']} cursor={false} speed={randomSpeed()} /></Link>
          </div>
          : null
      }


    </div >
  );
}

export default App;
