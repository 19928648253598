import { useState } from 'react';

import './Patterns.css';

import { jsPDF } from 'jspdf';

const PAGE_LARGE = 11
const PAGE_SMALL = 8.5

type Piece = {
    width: number;
    height: number;
    seamAllowance: number;
    label: string;
}

/**
 * A wrapper class for use in bin packing algorithms.
 */
class AlgoPiece {
    readonly piece: Piece
    readonly width: number
    readonly height: number
    readonly largestDim: number
    readonly fitsSingleSheet: boolean

    // Top left coord of placed piece.
    x: number
    y: number

    constructor(piece: Piece) {
        this.piece = piece

        this.width = piece.width + (piece.seamAllowance * 2)
        this.height = piece.height + (piece.seamAllowance * 2)

        if (this.width > this.height) {
            this.largestDim = this.width
            this.fitsSingleSheet = this.width < PAGE_LARGE && this.height < PAGE_SMALL
        } else {
            this.largestDim = this.height
            this.fitsSingleSheet = this.height < PAGE_LARGE && this.width < PAGE_SMALL
        }

        this.x = 0
        this.y = 0
    }
}

const rangesOverlap = (a1: number, a2: number, b1: number, b2: number): boolean => {
    return !((a2 < b1) || (b2 < a1))
}


class AlgoResult {
    placements: AlgoPiece[][]
    sheetWidth: number // Number of sheets left to right

    constructor() {
        this.placements = []
        this.sheetWidth = 0
    }

    yFromRow(rowIndex: number): number {
        let result = 0;
        for (let i = 0; i < rowIndex; i++) {
            result += this.placements[i][0].height
        }
        return result;
    }

    xFromRow(rowIndex: number): number {
        let result = 0;
        for (let i = 0; i < this.placements[rowIndex].length; i++) {
            result += this.placements[rowIndex][i].width
        }
        return result;
    }


    toPDF(designName: string): jsPDF {
        // NOTE: This is tightly coupled to the packing implementation.
        const minHeight = this.placements.reduce((prev, row) => prev + row[0].height, 0)
        const sheetHeight = Math.ceil(minHeight / PAGE_LARGE)

        const doc = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: [PAGE_SMALL, PAGE_LARGE]
        });

        // Iterate over each page in the PDF.
        for (let i = 0; i < sheetHeight; i++) {
            for (let j = 0; j < this.sheetWidth; j++) {
                if (!(i === 0 && j === 0)) {
                    // The first page is already added.
                    doc.addPage()
                }

                // For sheet i/j, find all rects that should be on this sheet.
                const y1 = i * PAGE_LARGE
                const y2 = y1 + PAGE_LARGE
                const x1 = j * PAGE_SMALL
                const x2 = x1 + PAGE_SMALL

                // The strategy here is to draw the pieces at their absolute coordinates, but "shifted" by the coordinates of the upper-left
                // corner of the current page that is in focus. Those coordinates are x1/y1 here. I label them xShift/yShift for readability.
                const xShift = x1
                const yShift = y1

                this.placements.forEach(row => {
                    row.forEach(p => {
                        // If X range is overlapping and Y range is overlapping.
                        if (rangesOverlap(x1, x2, p.x, p.x + p.width) && rangesOverlap(y1, y2, p.y, p.y + p.height)) {
                            const sa = p.piece.seamAllowance

                            // Draw the piece, with an appropriate translation.
                            doc.setLineWidth(.03)
                            doc.setLineDashPattern([1, 0], 0) // Solid line
                            doc.setFillColor(169, 169, 169)
                            doc.rect(p.x - xShift, p.y - yShift, p.width, p.height, 'DF')
                            // Tile labels on piece.
                            doc.setFontSize(6)
                            for (let tileCol = 0; tileCol < p.piece.width; tileCol++) {
                                for (let tileRow = 0; tileRow < p.piece.height; tileRow++) {
                                    doc.text(
                                        [designName, p.piece.label, `${p.piece.width}x${p.piece.height}`],
                                        p.x - xShift + tileCol + sa + 0.1, // 0.1 is just some margin
                                        p.y - yShift + tileRow + sa + 0.1,
                                        { 'align': 'left', 'maxWidth': 1, 'baseline': 'top' }
                                    );
                                }
                            }
                            // Draw seam allowances
                            if (sa !== 0) {
                                doc.setLineWidth(.02)
                                doc.setLineDashPattern([.05, .02], 0)
                                // TODO: Define helper line shim function that automatically subtracts shifts.
                                doc.line(p.x - xShift, p.y + sa - yShift, p.x + p.width - xShift, p.y + sa - yShift) // top
                                doc.line(p.x - xShift, p.y + p.height - sa - yShift, p.x + p.width - xShift, p.y + p.height - sa - yShift) // bottom
                                doc.line(p.x + sa - xShift, p.y - yShift, p.x + sa - xShift, p.y + p.height - yShift) // left
                                doc.line(p.x + p.width - sa - xShift, p.y - yShift, p.x + p.width - sa - xShift, p.y + p.height - yShift) // right
                            }
                        }
                    })
                })
            }
        }

        return doc
    }
}

// Problem statement: Given N rectangles, arrange them in a non-overlapping way such that the result fits onto the smallest number of 8.5x11 sheets.
// Additional constraint: Any single rectangle that can fit onto a single 8.5x11 sheet must not be split across sheets.
interface PackingAlgorithm {
    (rectangles: AlgoPiece[]): AlgoResult
}

/**
 * Best-Fit Decreasing Height.
 */
const BFDHAlgorithm: PackingAlgorithm = (rectangles: AlgoPiece[]): AlgoResult => {
    const result = new AlgoResult()

    // Sort tallest to shortest.
    const rects = rectangles.sort((a, b) => b.height - a.height)

    rects.forEach(piece => {
        // Every row's height is defined by the left most piece in the row. We start
        // a new row whenever the next piece doesn't fit in any existing row. "Doesn't fit" means
        // it would cause a sheet width expansion. Otherwise, we pick the row that can accomodate 
        // the new piece with the least leftover space.
        let widestValidRowIndex: null | number = null;
        let widestValidRodWidth: null | number = null;
        result.placements.forEach((row, rowIndex) => {
            const rowWidth = row.reduce((prev, p) => prev + p.width, 0)
            if (rowWidth + piece.width <= result.sheetWidth * PAGE_SMALL) {
                // It fits. See if this row is the best candidate.
                if (widestValidRodWidth == null || rowWidth > widestValidRodWidth) {
                    widestValidRodWidth = rowWidth
                    widestValidRowIndex = rowIndex
                }
            }
        })

        if (widestValidRowIndex != null) {
            // This existing row works, place the piece.
            piece.x = result.xFromRow(widestValidRowIndex)
            piece.y = result.yFromRow(widestValidRowIndex)
            result.placements[widestValidRowIndex].push(piece)
        } else {
            // We need to add a new row. Potentially need to do a width sheet expansion.
            if (piece.width > result.sheetWidth * PAGE_SMALL) {
                const minSheetWidth = Math.ceil(piece.width / PAGE_SMALL);
                result.sheetWidth = minSheetWidth;
            }

            // Place the piece in the new row.
            result.placements.push([])
            piece.x = result.xFromRow(result.placements.length - 1)
            piece.y = result.yFromRow(result.placements.length - 1)
            result.placements[result.placements.length - 1].push(piece)
        }
    })

    console.log(result)

    return result
}

function PatternsPage() {
    const [pieces, setPieces] = useState<Piece[]>([]);

    // Design title
    const [designName, setDesignName] = useState<string>('');

    // Piece form state
    const [curLabel, setCurLabel] = useState<string>('');
    const [curWidth, setCurWidth] = useState<number>(1);
    const [curHeight, setCurHeight] = useState<number>(1);
    const [curSeamAllowance, setCurSeamAllowance] = useState<number>(0.0);

    const onDesignNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDesignName(event.target.value)
    }

    const onCurLabelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurLabel(event.target.value)
    }

    const onCurWidthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(event.target.value)
        setCurWidth(val)
    }

    const onCurHeightChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(event.target.value)
        setCurHeight(val)
    }

    const onCurSeamAllowanceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(event.target.value)
        setCurSeamAllowance(val)
    }

    const onAddPiece = () => {
        // TODO: validation
        setPieces([...pieces, { width: curWidth, height: curHeight, seamAllowance: curSeamAllowance, label: curLabel }])
    }

    const onGenerate = () => {
        const algoPieces: AlgoPiece[] = pieces.map(p => new AlgoPiece(p))
        const result: AlgoResult = BFDHAlgorithm(algoPieces)
        const pdf = result.toPDF(designName)

        // Preview in browser
        window.open(pdf.output('bloburl'), '_blank');
        // Save file
        // doc.save("pattern.pdf");
    }

    return (
        <div className='patterns'>
            <h1>Sewing Pattern Generator</h1>
            <div>
                <p><i>This tool generates pattern PDFs for simple shapes. All units are inches.</i></p>
                Design name: <input type='text' value={designName} onChange={onDesignNameChange}></input>
                <hr />
                Piece label: <input type='text' value={curLabel} onChange={onCurLabelChanged}></input>
                <br />
                Width: <input type='number' value={curWidth} onChange={onCurWidthChanged}></input>
                <br />
                Height: <input type='number' value={curHeight} onChange={onCurHeightChanged}></input>
                <br />
                Seam Allowance: <input type='number' value={curSeamAllowance} onChange={onCurSeamAllowanceChanged}></input>
                <br />
                <button onClick={onAddPiece}>Add Piece</button>
                <table>
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>W x H</th>
                            <th>Seam Allowance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pieces.map((p, i) => { return <tr key={i}><td>{p.label}</td><td>{p.width} x {p.height}</td><td>{p.seamAllowance}</td></tr> })}
                    </tbody>
                </table>
                <hr />
                <button onClick={onGenerate}>Generate Pattern PDF</button>
            </div>
        </div>
    )
}

export default PatternsPage;
