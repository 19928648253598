import './Runalytics.css';

import { useState, useEffect, useRef } from 'react';

import { XAxis, YAxis, Tooltip, ScatterChart, Scatter, Label, ResponsiveContainer, CartesianGrid, } from 'recharts';
import moment from 'moment';

import * as THREE from 'three';
import * as THREE_ORBITCONTROLS from 'three-orbitcontrols-ts';



function RunalyticsPage() {

    const [index, setIndex] = useState<null | []>(null);
    const [activities, setActivities] = useState<any>({}); // TODO: figure out how to type this
    const [dataFetched, setDataFetched] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState<any>(null);

    const animationDiv = useRef<any>();

    const fetchData = () => {
        fetch('https://rileychang-runalytics.s3.us-west-2.amazonaws.com/processed/index.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(indexData => {
            setIndex(indexData)

            const fetchTasks: Promise<void | Response>[] = []
            indexData.forEach((activity: string) => {
                fetchTasks.push(
                    fetch('https://rileychang-runalytics.s3.us-west-2.amazonaws.com/processed/' + activity, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }).then(response => {
                        return response.json()
                    }).then(activityData => {
                        const activityId = activity.split('.')[0] // Unique ID for each activity.
                        activityData['id'] = activityId
                        activityData['date'] = activityData['date'] * 1000 // Javascript dates expect millis...
                        setActivities((old: {}) => {
                            return { ...old, [activityId]: activityData }
                        })
                    })
                )
            })
            Promise.all(fetchTasks).then((results) => {
                setDataFetched(true)
            })
        })
    }
    useEffect(() => { fetchData() }, [])

    const calculateTotalMiles = () => {
        let miles = 0
        for (const act of Object.values(activities)) {
            const activity = act as Record<string, any>
            if (new Date(activity['date']).getFullYear() == 2023)
                miles += activity['miles']
        }
        return miles
    }

    const dateFormatter = (unixTime: number): string => {
        return moment(unixTime).format('MM/DD/YY')
    }

    const tooltipFormatter = (value: string, name: string, props: any) => {
        if (name === 'miles') {
            // return value rounded
            return Number(value).toFixed(1)
        } else if (name === 'date') {
            // return formatted date via moment function
            return dateFormatter(Number(value))
        }
        return "ERROR"
    }

    const getLoadingStatus = () => {
        const activitiesLength = Object.keys(activities).length
        if (!index) {
            return "Loading data..."
        } else if (index && activitiesLength < index.length) {
            return `Fetching activity data... (${activitiesLength} / ${index.length})`
        } else {
            return `Loaded ${activitiesLength} / ${activitiesLength} activities.`
        }
    }

    const normalizeGPSPoints = (points: any[]): any[] => {
        // Identify the min/max values
        let maxLat = points[0]['lat']
        let minLat = maxLat
        let maxLng = points[0]['lng']
        let minLng = maxLng
        let maxEle = points[0]['ele']
        let minEle = maxEle
        for (const point of points) {
            if (point['lat'] > maxLat)
                maxLat = point['lat']
            if (point['lat'] < minLat)
                minLat = point['lat']
            if (point['lng'] > maxLng)
                maxLng = point['lng']
            if (point['lng'] < minLng)
                minLng = point['lng']
            if (point['ele'] > maxEle)
                maxEle = point['ele']
            if (point['ele'] < minEle)
                minEle = point['ele']
        }

        // Create a new set of points with values normalized based on min/max above.

        const lngRange = maxLng - minLng
        const latRange = maxLat - minLat
        const lngMid = minLng + (lngRange / 2)
        const latMid = minLat + (latRange / 2)

        // We want to make sure the entire thing fits inside a cube and also doesn't waste space.
        // So we scale the "widest" dimension to fit the cube.
        const scaleFactor = lngRange > latRange ? 25 / (maxLng - lngMid) : 25 / (maxLat - latMid)

        const normalizedPoints = []
        for (const point of points) {
            const newPoint = {
                // Translate the lat/lng to center on 0, then scale to fill.
                'lat': (point['lat'] - latMid) * scaleFactor,
                'lng': (point['lng'] - lngMid) * scaleFactor,
                // Translate elevation to have a min of 0, then scale to fill the "cube".
                // NOTE: The elevation scale does not match the coordinates scale. May need to play with this to find the right number.
                'ele': (point['ele'] - minEle) * (25 / (maxEle - minEle))
            }
            normalizedPoints.push(newPoint)
        }

        return normalizedPoints
    }

    const renderAnimation = (activityId: string) => {
        // Get the size of the container element. It has 0 height before rendering, so
        // we just make it a square.
        const divWidth = animationDiv.current.offsetWidth
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, divWidth / divWidth, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(divWidth, divWidth);

        // We use a ref to integrate the THREE canvas with the React DOM.
        animationDiv.current.replaceChildren(renderer.domElement)

        // Grid
        const gridHelper = new THREE.GridHelper(50, 10);
        gridHelper.position.set(0, 0, 0) // this positions the center of the grid
        scene.add(gridHelper);

        const normalizedPoints = normalizeGPSPoints(activities[activityId]['points'])

        // Render the points.
        for (const point of normalizedPoints) {
            const geometry = new THREE.SphereGeometry(0.2, 16, 16);
            const material = new THREE.MeshBasicMaterial({ color: 0x0B17EE });
            const sphere = new THREE.Mesh(geometry, material);
            sphere.position.set(point['lat'], point['ele'], point['lng'])
            scene.add(sphere);
        }

        // Setup camera position and mouse controls
        camera.position.z = 100;
        camera.position.y = 10;
        const controls = new THREE_ORBITCONTROLS.OrbitControls(camera, renderer.domElement);
        controls.enableZoom = true

        function animate() {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        }
        animate()
    }

    const changeActivity = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedActivity(e.currentTarget.value)
        renderAnimation(e.currentTarget.value)
    }

    const renderActivityDetails = () => {
        const activity = activities[selectedActivity]
        return <>
            <p className='bold'>Name: {activity['name']}</p>
            <p className='bold'>Date: {dateFormatter(activity['date'])}</p>
            <p className='bold'>Miles: {activity['miles'].toFixed(2)}</p>
        </>
    }

    return (
        <div className='runalytics'>
            <h1>Exercise</h1>
            <h3>This is my Strava data! I built some features that I didn't want to pay for.</h3>
            <h3>
                {getLoadingStatus()}
            </h3>
            <hr />

            {dataFetched &&
                <div>
                    <h3>
                        Total miles in 2023: {Number(calculateTotalMiles()).toFixed(0)} / 500
                    </h3>

                    <ResponsiveContainer width='95%' height={500}>
                        <ScatterChart margin={{ top: 10, right: 10, bottom: 100, left: 100 }}>
                            <XAxis
                                dataKey='date'
                                type='number'
                                domain={['auto', 'auto']}
                                tickFormatter={dateFormatter}
                            >
                                <Label value="date" position="bottom" />
                            </XAxis>
                            <YAxis dataKey='miles'>
                                <Label value='miles' position="left" />
                            </YAxis>
                            <Scatter data={Object.values(activities)} />
                            <CartesianGrid></CartesianGrid>
                            <Tooltip formatter={tooltipFormatter} separator=': '></Tooltip>
                        </ScatterChart>
                    </ResponsiveContainer>

                </div>
            }

            <div>
                <h3>Activity Details</h3>
                <select name="exercise" id="exercise" onChange={changeActivity}>
                    {
                        Object.keys(activities).map(a => <option key={a} value={a}>{activities[a]['name']}</option>)
                    }
                </select>
                <div className="row">
                    <div className="narrow">{selectedActivity && renderActivityDetails()}</div>
                    <div className="wide">
                        <div id="three" ref={animationDiv}></div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default RunalyticsPage;
