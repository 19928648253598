import LoadedPhoto from "./LoadedPhoto";
import "./Photography.css";

import { useState } from "react";

const photos = [
  {
    url: "https://rileychang-photography.s3.us-west-2.amazonaws.com/DSCF9115.jpg",
    caption: "Fall trees at the Red River Gorge",
  },
  {
    url: "https://rileychang-photography.s3.us-west-2.amazonaws.com/DSCF9456.jpg",
    caption: "Lake Michigan",
  },
  {
    url: "https://rileychang-photography.s3.us-west-2.amazonaws.com/DSCF9364.JPG",
    caption: "Climber on Tacit, 5.12a",
  },
  {
    url: "https://rileychang-photography.s3.us-west-2.amazonaws.com/DSCF9299.JPG",
    caption: "Climber on Betavul Pipeline, 5.12a",
  },
  {
    url: "https://rileychang-photography.s3.us-west-2.amazonaws.com/DSCF9334.JPG",
    caption: "Bob Marley Wall, Red River Gorge",
  },
  {
    url: "https://rileychang-photography.s3.us-west-2.amazonaws.com/DJI_0152.JPG",
    caption: "Snorkeling off the coast of Krabi, Thailand",
  },
  {
    url: "https://rileychang-photography.s3.us-west-2.amazonaws.com/DJI_0081-2.jpg",
    caption: "Smith Rock",
  },
];

function PhotographyPage() {
  const [photosLoaded, setPhotosLoaded] = useState(3);

  const renderPhotos = () => {
    const renderedPhotos = [];
    for (let i = 0; i < photosLoaded && i < photos.length; i++) {
      const photoData = photos[i];
      renderedPhotos.push(
        <LoadedPhoto
          url={photoData.url}
          caption={photoData.caption}
        ></LoadedPhoto>
      );
    }
    return renderedPhotos;
  };

  return (
    <div className="photography">
      <h1>Photography</h1>
      <p>
        I like taking photos. Here are some of my favorites. You can find more
        photos <a href="https://www.instagram.com/jpeg.riley">@jpeg.riley</a>
      </p>

      {renderPhotos()}

      {photosLoaded < photos.length ? (
        <button
          onClick={() => {
            setPhotosLoaded((prev) => prev + 3);
          }}
        >
          Load more
        </button>
      ) : null}
    </div>
  );
}

export default PhotographyPage;
