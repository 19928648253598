import './Hangboard.css';

import { useState, useEffect } from 'react';

import { speak } from '../utils';

function HangboardPage() {
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState<boolean>(false);

    function start() {
        setIsActive(!isActive);
    }

    function reset() {
        setIsActive(false);
        setSeconds(0);
    }

    const playSound = () => {
        var chime = document.getElementById("chime") as any;
        chime.play();
    }

    // TODO: allow user to control how many rounds and rest/hang durations.
    // TODO: add a "done" sound

    useEffect(() => {
        let interval: any = null;
        if (isActive) {
            if (seconds == 0)
                playSound();
            interval = setInterval(() => {
                const newSeconds = seconds + 1;
                setSeconds(newSeconds);
                if (newSeconds % 7 == 0)
                    // speak(newSeconds.toString(), 1.5)
                    playSound();
                if (newSeconds > (7 * 7 * 2))
                    setIsActive(false)
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);


    return (
        <div className='hangboard'>
            <audio id="chime" src="/chime.mp3">
                Your browser does not support the<code>audio</code> element.
            </audio>
            <h1>Hangboard</h1>
            <p>This is a  tool to assist with hangboard workouts.</p>
            <h2>Repeaters</h2>
            <p>This plays a sound every 7 seconds for 7 rounds.</p>
            <div className={`time ${isActive ? "active" : "inactive"}`}>{Math.floor(seconds / 60)} min {seconds % 60} sec</div>
            <button onClick={start}>{isActive ? "Stop" : "Start"}</button>
            <button onClick={reset}>Reset</button>
        </div>
    )
}

export default HangboardPage;
